<template>
  <div class="d-flex align-center">
    <div style="min-width: 50%">{{ entityCopy.name }}</div>

    <div class="pa-1 reduce-padding">
      <date-picker
        @selected-date="
          value => {
            setDate(value, 'startDate');
          }
        "
        :key="`employe-start-date-${entityCopy.group}-${entityCopy.timelineId}`"
        :dateProp="startDate"
        :maxDateProp="endDate.picker"
        :canReadOnly="canReadOnly"
      ></date-picker>
    </div>

    <div class="pa-1 reduce-padding">
      <date-picker
        @selected-date="
          value => {
            setDate(value, 'endDate');
          }
        "
        :key="`employe-end-date-${entityCopy.group}-${entityCopy.timelineId}`"
        :dateProp="endDate"
        :minDateProp="startDate.picker"
        :canReadOnly="canReadOnly"
      ></date-picker>
    </div>

    <div style="width: 50px;" class="reduce-padding mr-2">
      <v-text-field v-model="entityCopy.days" disabled dense solo outlined flat hide-details></v-text-field>
    </div>

    <div style="width: 14px">
      <v-btn @click="$emit('delete-timeline')" fab width="14px" height="14px" color="grey" depressed :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }"
        ><v-icon x-small color="white">{{ icons.mdiClose }}</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import { mdiClose } from "@mdi/js";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";

export default {
  name: "ItemSummaryRow",

  components: {
    DatePicker
  },

  props: {
    entity: {
      type: Object,
      required: true,
      default: () => {}
    },

    canReadOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      entityCopy: {},
      startDate: { holder: "", picker: "" },
      endDate: { holder: "", picker: "" }
    };
  },

  created() {
    this.entityCopy = this.entity;

    const { end, start } = this.entity;
    this.startDate.picker = start;
    this.startDate.holder = this.momentDate(start, "DD/MM/YYYY");

    this.endDate.picker = end;
    this.endDate.holder = this.momentDate(end, "DD/MM/YYYY");
  },

  mixins: [MomentMixin],

  methods: {
    setDate(value, property) {
      const { picker, holder } = value;
      this[property].picker = picker;
      this[property].holder = holder;

      this.editHandler();
    },

    editHandler() {
      const { timelineId, group } = this.entity;

      const payload = {
        timelineId,
        group,
        start: this.startDate.picker,
        end: this.endDate.picker
      };

      this.$emit("edit-timeline", payload);
    }
  },

  computed: {
    icons: () => ({ mdiClose })
  },

  watch: {
    entity: {
      handler: function(newVal) {
        if (newVal) this.entityCopy = newVal;

        const { end, start } = newVal;
        this.startDate.picker = start;
        this.startDate.holder = this.momentDate(start, "DD/MM/YYYY");

        this.endDate.picker = end;
        this.endDate.holder = this.momentDate(end, "DD/MM/YYYY");
      },
      deep: true
    }
  }
};
</script>
