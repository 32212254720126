<template>
  <v-menu v-model="menu" transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="dateHolder" :placeholder="translations.select + '...'" :disabled="disablePicker"
                    :clearable="clearable" @click:clear="clearDate"
                    class="pa-0" v-on="on" v-bind="attrs" outlined readonly dense hide-details prepend-icon="mdi-calendar" />
    </template>

    <v-date-picker v-model="date"
                   :min="minDate"
                   :max="maxDateProp"
                   :allowed-dates="getAvailableDates"
                   locale="it"
                   :class="[...classToAdd]"
                   @change="(value) => dateHandler(value)" :disabled="canReadOnly"/>
  </v-menu>
</template>


<script>
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import { translations } from '@/utils/common'

export default {
  props: {
    dateProp: {
      type: Object,
      default: () => {
      }
    },

    minDateProp: {
      type: String,
      default: () => ''
    },

    maxDateProp: {
      type: String,
      default: () => ''
    },

    startToDate: {
      type: String,
      default: () => ''
    },

    disablePicker: {
      type: Boolean,
      default: false
    },

    excludedDates: {
      type: Array,
      default: () => []
    },

    // this is used to pass date-picker-component class to manipulate the disabled td background color
    classToAdd: {
      type: Array,
      default: () => []
    },

    applyMinDate: {
      type: Boolean,
      default: true
    },

    clearFields: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    },

    canReadOnly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    date: null,
    dateHolder: null,
    menu: false
  }),

  created() {
    if (!this.dateProp?.picker) return
    this.setPropInfo()
  },

  mixins: [MomentMixin],

  methods: {
    getAvailableDates(val) {
      return !this.excludedDates.includes(val)
    },

    dateHandler(value) {
      const simpleFormat = this.simpleFormat(value)
      this.dateHolder = this.momentDate(value, 'DD/MM/YYYY')
      this.menu = false

      this.$emit('selected-date', {
        simple: simpleFormat,
        holder: this.dateHolder,
        picker: value
      })
    },

    setPropInfo() {
      this.date = this.dateProp.picker
      this.dateHolder = this.dateProp.holder
    },

    clearDate() {
      this.date = null
      this.dateHolder = null

      this.$emit('cleared-fields')
      this.$emit('selected-date', {
        simple: null,
        holder: null,
        picker: null
      })
    }
  },

  computed: {
    translations: () => translations,

    minDate() {
      if (this.minDateProp) return this.minDateProp
      if (!this.applyMinDate) return ''
      return ''
    }
  },

  watch: {
    dateProp: {
      handler() {
        this.setPropInfo()
      },
      deep: true // Add this if dateProp is an object to watch for nested changes.
    },

    startToDate() {
      this.date = this.startToDate
    },

    clearFields(newVal) {
      if (newVal) {
        this.clearDate()
      }
    }
  }
}
</script>


<style>
div.date-picker-component > div > div > div > table > tbody > tr > td > button.v-btn--disabled {
  background-color: rgb(255, 0, 0, 0.1);
}
</style>
