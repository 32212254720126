<template>
  <v-dialog v-model="show" persistent width="460px">
    <!-- Wrapper -->
    <v-card class="wrapper pa-3" :style="[{ borderTop: `4px solid ${mainColor}` }]">
      <!-- Title and close action -->
      <div class="d-flex align-center justify-space-between mt-2 mb-4">
        <div class="d-inline-block align-center">
          <span class="main--text mr-2" style="font-size: 1rem"> {{ upperCase(translations.project) }}: </span>
          <span style="font-size: 1rem">{{ project.content }}</span>
        </div>

        <div class="d-inline-block">
          <v-btn @click="$emit('close')" fab width="24px" height="24px" color="main" depressed
            ><v-icon small color="white">{{ icons.mdiClose }}</v-icon></v-btn
          >
        </div>
      </div>

      <!-- Contract end date -->
      <div class="mb-2">
        <span class="main--text mr-2">{{ upperCase(translations.dateEndOfContract) }}:</span>
        <span>{{ projectDates.end }}</span>
      </div>

      <!-- Start and End Date -->

      <div class="d-flex mb-2">
        <div class="d-inline-block mr-4">
          <p class="ma-0 main--text">{{ upperCase(translations.startDate) }}</p>
          <v-text-field v-model="projectDates.start" dense solo hide-details :placeholder="upperCase(translations.startDate)" disabled></v-text-field>
        </div>

        <div class="d-inline-block">
          <p class="ma-0 main--text">{{ upperCase(translations.endDate) }}</p>
          <v-text-field v-model="projectDates.end" dense solo hide-details :placeholder="upperCase(translations.endDate)" disabled></v-text-field>
        </div>
      </div>

      <!-- Total contract days -->
      <div class="mb-2">
        <span class="main--text mr-2">{{ upperCase(translations.totalAllocatedDays) }}:</span>
        <span>{{ project.allocatedDays }}</span>
      </div>

      <!-- Employees -->
      <div v-if="employeeList.length > 0" class="mb-2">
        <p class="ma-0 main--text">
          {{ upperCase(translations.squad) }}
        </p>

        <div class="vertical-scroll-bar pr-2" style="max-height: 500px">
          <template>
            <item-summary-row
              v-for="employee in employeeList"
              :key="`${employee.group}-${employee.timelineId}`"
              :entity="employee"
              :canReadOnly="canReadOnly"
              @delete-timeline="$emit('delete-timeline', employee)"
              @edit-timeline="
                value => {
                  $emit('edit-timeline', value);
                }
              "
            ></item-summary-row>
          </template>
        </div>
      </div>

      <!-- Suppliers -->
      <div v-if="supplierList.length > 0">
        <p class="ma-0 main--text">
          {{ upperCase(translations.suppliers) }}
        </p>

        <div class="vertical-scroll-bar pr-2" style="max-height: 500px">
          <template>
            <item-summary-row
              v-for="supplier in supplierList"
              :key="`${supplier.group}-${supplier.timelineId}`"
              :entity="supplier"
              :canReadOnly="canReadOnly"
              @delete-timeline="$emit('delete-timeline', supplier)"
              @edit-timeline="
                value => {
                  $emit('edit-timeline', value);
                }
              "
            ></item-summary-row>
          </template>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
import ItemSummaryRow from "./ItemSummaryRow.vue";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";
import { translations } from "@/utils/common";

export default {
  components: {
    ItemSummaryRow
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },

    project: {
      type: Object,
      required: true,
      default: () => {}
    },

    employeeList: {
      type: Array,
      required: true,
      default: () => []
    },

    supplierList: {
      type: Array,
      required: true,
      default: () => []
    },

    canReadOnly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    startDate: "28/02/2022",
    endDate: "20/04/2022"
  }),

  mixins: [MomentMixin],

  methods: {
    upperCase(string) {
      return string.toUpperCase();
    }
  },

  computed: {
    translations: () => translations,

    mainColor() {
      return this.$vuetify.theme.themes.light.main;
    },

    icons: () => ({ mdiClose }),

    projectDates() {
      if (!this.project?.project?.dateFinishedStr) return { start: "", end: "" };
      const { dateFinishedStr, dateStartedStr } = this.project?.project;

      return { start: this.momentDate(dateStartedStr, "DD/MM/YYYY"), end: this.momentDate(dateFinishedStr, "DD/MM/YYYY") };
    }
  }
};
</script>

<style>
.vertical-scroll-bar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
.vertical-scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.vertical-scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.vertical-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.vertical-scroll-bar {
  overflow-y: auto;
}

.wrapper {
  max-width: 500px;
  height: 100%;
  background-color: #fff;
}

.reduce-padding > .v-text-field > .v-input__control > .v-input__slot {
  max-height: 26px !important;
  min-height: 26px !important;
}

.reduce-padding > .v-text-field > .v-input__control {
  max-height: 26px !important;
  min-height: 26px !important;
}

.reduce-padding .v-text-field .v-input__control .v-input__slot {
  padding: 0 4px !important;
  font-size: 12px !important;
  max-height: 26px !important;
  min-height: 26px !important;
}
</style>
